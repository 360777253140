@import "src/assets/styles/include-media";

.console {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  canvas {
    position: static !important;
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: 1852 / 817 !important;
    max-width: 100%;
    object-fit: contain;
  }

  height: 100%;
  flex: 1 1;
  border: none;

  &-vcd {
    align-items: center;
    //position: absolute;
    //left: 0;

    //&-widget {
    //  width: 70%;
    //}
  }

  &-widget {
    height: 100%;
    &-opened {
      // position: absolute;
      // left: 74.5%;
      // top: 12%;

      padding-top: 1px;
      width: 23.1%;
      height: 100%;
      border: none;
      transition: top 0.6s;
    }
    &-closed {
      $size: 45px;
      $borderWidth: 1.5px;
      position: absolute;
      //left: calc(100vw - 85px);
      //right: 0;
      top: 88%;
      display: flex;
      height: $size * 1.1;
      //@include media("<=886px",) {
      //  left: calc(100vw);
      //}
      //@include media("<=desktop", ">lappy") {
      //  left: 90.35%
      //}
      //@include media("<=lappy") {
      //  left: 89.35%
      //}
      .half-circle {
        display: inline-block;
        background: var(--color-white);
        border: $borderWidth solid var(--color-primary);
        border-right: none;
        width: $size;
        border-bottom-left-radius: $size * 0.6;
        border-top-left-radius: $size * 0.6;
      }
      .lines {
        display: inline-block;
        border: $borderWidth solid var(--color-primary);
        border-left: none;
        background: var(--color-white);
        width: $size * 0.8;
        border-right: none;
        b {
          font-size: 1rem;
          display: inline-block;
          margin-top: 11px;
          margin-left: 5px;
          color: var(--color-primary);
        }
        img {
          height: 14px;
          margin-bottom: 3px;
          margin-left: -30px;
        }
      }
    }
  }
  &-presentation {
    flex: 1;
    //height: calc(100% - 60px);
  }
  &-time-alert {
    position: absolute;
    bottom: -15px;
    left: 42%;
    & .alert {
      border-radius: 10px 10px 0 0;
      padding: 0.75rem 1.25rem;
    }
    & button {
      padding: 2px 10px;
      border-radius: 5px;
      font-size: 0.9rem;
    }
  }
}

.portal-window {
  //@include media('') {
  //  min-width: 30%;
  //  max-width: 70%;
  //}
}