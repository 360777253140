@import "./variables";

@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/proxima-ssv/ProximaNova-Regular.otf");
}

body {
  margin: 0;
  font-size: 0.9rem;
  //min-width: 900px;
  overflow-y: hidden;
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-primary {
  color: var(--color-primary);
}

.seperator {
  font-size: 0.8rem;
  color: #88878c;
}
.seperator::before,
.seperator::after {
  display: inline-block;
  content: "";
  border-top: 1px solid #88878c;
  width: 5.5rem;
  margin: 0 1rem;
  transform: translateY(-0.3rem);
}

.border-none {
  border: none !important;
}

.input-icon-background {
  background-color: var(--color-white);
  border: none;
}

@for $value from 1 through 100 {
  $width: percentage($value);

  .table-col-#{$value} {
    width: $width;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.status {
  border-radius: 1.4rem;
  padding: 0.1rem 1.4rem;
  width: 5rem;
  &-enabled {
    background-color: #00a00015;
    color: var(--color-green-text);
  }
  &-used {
    background-color: #e3f5ff;
    color: var(--color-light-blue-text);
  }
  &-disabled {
    background-color: #efefef;
    color: var(--color-disabled-text);
  }
  &-draft {
    background-color: #e3f5ff;
    color: var(--color-light-blue-text);
  }
  &-expired {
    background-color: #e8dfdb;
    color: #dc3545;
  }
}

.tags-pill {
  border-radius: 25px;
  padding: 0px 4px;
  background-color: #e8e8ff;
  color: #8b86ec;
  border: 1px solid #d6d6f9;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clearfix {
  margin-bottom: 0.25rem;
}

.underline {
  text-decoration: underline;
}

.pure-radio {
  .container {
    display: block;
    margin: 0;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 1px;
    height: 1rem;
    width: 1rem;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: var(--color-primary);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
  }
}

.width-95 {
  width: 95%;
}

.text-white {
  color: white;
}

.pure-link-decoration-none {
  text-decoration: none !important;
  color: inherit;
  :hover {
    text-decoration: none !important;
  }
}

.action-icon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.3rem;
  background-color: rgba(254, 80, 9, 0.07);
  display: flex;
  justify-content: center;
  border-radius: 0.2rem;
  cursor: pointer;
  &-lab-list {
    height: 1.9rem;
    width: 1.8rem;
  }
}

.secondary-text-color {
  color: var(--color-grey-text);
}

.transparent-bg {
  background-color: transparent;
}

.bg-white {
  background-color: var(--color-white);
}

::-webkit-scrollbar {
  width: 4px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #696b6f;
  border-radius: 6px;
  width: 6px;
  height: 6px;
  &:hover {
    background: var(--color-primary);
  }
}

.no-result-found {
  margin-top: 10rem;
}

.no-result-add-btn {
  width: fit-content;
  align-self: center;
}

.btn-bg-white {
  background-color: var(--color-white);
}

.center-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.border-primary {
  border: 1px solid var(--color-primary);
}

.highest-ZIndex {
  z-index: 20000;
}

.font-small {
  font-size: 0.8rem;
}

.font-large {
  font-size: 1.1rem !important;
}

.font-smaller {
  font-size: 0.7rem;
}

.simple-btn {
  cursor: pointer;
  background: transparent;
  color: white;
  outline: none;
  border: none;
  font-size: smaller;

  &:focus {
    outline: none;
  }
}

.link-btn {
  background: transparent;
  outline: none;
  border: none;
}

.dynamic-content-modal {
  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.modal {
  z-index: 10001;
}