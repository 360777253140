.sidebar_admin-wrapper {
    // position: fixed;
    width: 65px;
    background-color: #383838;
    transition: all 0.5s ease;



    .sidebar_admin-branding {
        height: 55px;
        background-color: #2b2b2b;
        padding: 12px;
        white-space: nowrap;
        position: relative;
        cursor: pointer;

        & img:first-child {
            width: 34px;
        }

        & img:nth-child(2) {
            width: 133px;
            padding-left: 7px;
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        & img:nth-child(3) {
            position: absolute;
            right: 0px;
            top: 20px;
            background: #2b2b2b;
            padding-inline: 4px;

        }
    }

    .sidebar_admin-nav {
        overflow: hidden;
    }

    .sidebar_admin-item {
        font-size: 16px;
        display: block;
        color: #8d8d8d;
        border-left: 3px solid transparent;
        min-width: 200px;

        &:hover {
            color: #fff;
        }

        &.active {
            color: #fff;
            background-color: #444;
            border-color: #ff5929;
        }

        & .sidebar_admin-item-link {
            display: flex;
            height: 50px;
            align-items: center;
            justify-content: flex-start;
            color: inherit;
            text-decoration: none;
        }

        & .sidebar_admin-item-icon {
            padding: 0 13px;
            width: 50px;

        }

        & .sidebar_admin-item-text {
            opacity: 0;
            transition: opacity 0.3s ease;
        }


    }

    &.open {
        width: 200px;

        & .sidebar_admin-item-text,
        & .sidebar_admin-branding img:nth-child(2) {
            opacity: 1;
            // transition: opacity 0.5s ease 0.2s;
        }

        & .sidebar_admin-branding img:nth-child(3) {
            transform: rotate(180deg);
        }
    }

}