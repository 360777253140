.admin-layout {
  height: inherit;
  overflow: hidden;
  // background-color: var(--color-background);

  // .panel {
  //   width: 98%;
  // }
  .wrapper-panel {
    height: 100vh;
  }

  .panel {
    height: max-content;
    padding-inline: 0.74rem;
  }
}

// .main-panel {
//   overflow-y: scroll;
// }