.labs-manage-section {
  padding: 1.25rem 0.8rem;

  &-header {
    font-size: 1.5rem;
  }
}

.lab-tags {
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}

.delete-tag {
  cursor: pointer;
}

.lab {
  &-container {
    display: grid;
    grid-template-columns: 50% 50%;
    &-inner {
      display: grid;
      grid-template-columns: 32% 55% 13%;
    }

    .sort-order-weight-error {
      margin-bottom: 42px;
    }

    .lab-tags-input {
      margin-left: 32%;
      width: 56%;
      margin-top: -0.5rem;
      flex-flow: row wrap;
    }
  }
}


.select-presentation {
  margin-left: -4.5%;
  margin-right: 4.5%;
}

.large-font {
  font-size: 1rem;
}

.footer-btn {
  min-width: 7%;
}

.presentation-selected {
  background-color: #edf1f4;
}

.h-2 {
  height: 2rem;
}

.separator {
  border-bottom: 1px solid gainsboro;
}

.disable-color {
  color: #85989f;
}

.lab-cancel {
  &:hover {
    background: none;
    color: var(--color-primary);
  }
}

.react-select__control.react-select {
  &__control {
    &--is-disabled {
      background-color: #e9ecef;
    }

  }
}

.react-select__control {
  & .react-select {
    &__value-container {
      padding-left: 12px;
    }

    &__dropdown-indicator {
      color: #495057;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    &__placeholder {
      font-size: 16px;
      color: #495057;
    }
  }
}

.presentation-modal {
  z-index: 9999;
}